import React from 'react'
import { Link } from 'react-router-dom'

const PageNavigation = ({title}) => {
  return (
  <section className='PageNavigation-sec'>
    <Link to={'/'}>Home </Link>/ {title}
  </section>
  )
}

export default PageNavigation


