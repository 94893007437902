import React, { useContext, useState } from 'react'
import Hero from '../components/Hero'
import Services from '../components/Services'
import Trusted from '../components/Trusted'
import FeautureProduct from '../components/FeautureProduct'
import { ProductProvider } from '../Context/ProductContext'
import FetureSingleProduct from '../components/FetureSingleProduct'
import { productData } from '../data'
import { Link } from 'react-router-dom'

const Home = () => {
  const { ProductsData } = useContext(ProductProvider)
  const [MoreProducts,setMoreProducts] = useState(productData.slice(0,9));

  
  const data = {
    name: "Welcome to Rainwater Shah LLC "
  }
  return (
    <>
      <Hero myData={data} />
      <FeautureProduct />

      <Services />


      <section style={{textAlign:'center'}} className='fetureProduct-sec'>
        <div className="feture-container">
          <div className="intro-data">
            Cheak Now
          </div>
          <div className="comon-heading">
            Our Populer Products
          </div>
          <div className="main-container">
            {

              MoreProducts.map(item => <FetureSingleProduct key={item.id} {...item} />)
            }
          </div>
            <Link style={{marginTop:50,display:'inline-block',width:170}} to={'/products'} className='checkoutbtn'>See More</Link>
            
        </div>

      </section>
      <Trusted />
    </>

  )
}

export default Home
