import React from 'react'

const BellingInputs = () => {
return (
<div className='w-full lg:w-[40%]'>
    {/* ---- upper in form --- */}
    <div style={{border:"2px dotted red"}} className='py-[14px] px-[10px] '>
        <p className='font-normal text-red-500'>add <span className='text-red-500 font-semibold'>828.00</span> to cart
            get <span className='font-semibold'>Free Shipping !</span></p>
        <div className=' bg-slate-300 w-full my-2  relative h-[10px]'>
            <div className='w-[30%] bg-gradient-to-tr from-red-600 to-black h-full absolute top-0 left-0'></div>
        </div>
    </div>


    <form className="py-3 w-full my-5">
        <h2>BILLING DETAILS</h2>


        <div className="flex justify-start flex-col w-full my-4 items-start">
            <label>Name<span className='text-red-600'>*</span> </label>
            <input type="text" className="w-[100%]  h-[34px]   px-[10px] outline-none border-[#bdc1c2] rounded-md border-[1px]  border-solid" />
        </div>
        <div className="flex justify-start flex-col w-full my-4 items-start">
            <label>City<span className='text-red-600'>*</span> </label>
            <input type="text" className="w-[100%]  h-[34px]   px-[10px] outline-none border-[#bdc1c2] rounded-md border-[1px]  border-solid" />
        </div>
        <div className="flex justify-start flex-col w-full my-4 items-start">
            <label>Address line1<span className='text-red-600'>*</span> </label>
            <input type="text" className="w-[100%]  h-[34px]   px-[10px] outline-none border-[#bdc1c2] rounded-md border-[1px]  border-solid" />
        </div>
        <div className="flex justify-start flex-col w-full my-4 items-start">
            <label>Address line2<span className='text-red-600'>*</span> </label>
            <input type="text" className="w-[100%]  h-[34px]   px-[10px] outline-none border-[#bdc1c2] rounded-md border-[1px]  border-solid" />
        </div>
        <div className="flex justify-start flex-col w-full my-4 items-start">
            <label>ZIP code<span className='text-red-600'>*</span> </label>
            <input type="text" className="w-[100%]  h-[34px]   px-[10px] outline-none border-[#bdc1c2] rounded-md border-[1px]  border-solid" />
        </div>
        <div className="flex justify-start flex-col w-full my-4 items-start">
            <label>Phone<span className='text-red-600'>*</span> </label>
            <input type="text" className="w-[100%] we  h-[34px]   px-[10px] outline-none border-[#bdc1c2] rounded-md border-[1px]  border-solid" />
        </div>
        <div className="flex justify-start flex-col w-full my-4 items-start">
            <label>Email<span className='text-red-600'>*</span> </label>
            <input type="email" className="w-[100%]  h-[34px]   px-[10px] outline-none border-[#bdc1c2] rounded-md border-[1px]  border-solid" />
        </div>
        <div className="flex justify-start flex-col w-full my-4 items-start">
            <label>Order Notes (Optional) </label>
            <textarea placeholder="Notes About Your Order" className="w-[100%]  min-h-[150px] max-h-[300px] resize-none py-2  px-[10px] outline-none border-[#bdc1c2] rounded-md border-[1px]  border-solid" />
        </div>

    </form>

</div>
)
}

export default BellingInputs