import React from 'react'

const Order = () => {
  return (
    <div className='w-full h-[100vh] flex items-center justify-center'>
        <h2 className='text-[40px]'>We will Touch You Soon Thanks for your order</h2>
    </div>
  )
}

export default Order
