import  { useState } from 'react'

import { useContext } from 'react'
import GridView from '../components/Gridview'
import { ProductProvider } from '../Context/ProductContext'
import { Link } from 'react-router-dom'
import FormatPrice from '../Helper/FormatPrice'
import {BsFillGridFill} from 'react-icons/bs'
import {HiViewList} from 'react-icons//hi'
import ListView from '../components/ListView'

const Products = () => {
  const { ProductsData } = useContext(ProductProvider)
const [filterProduc,setFilerProduct] = useState([])
const [searchTerm,setSearchTerm] = useState([])
const [gridProduct,setGridProduct] = useState(true)

const [gridView,setGridView] = useState(true)
const [listView,setListView] = useState()




const changeGrid = () =>{
  setGridView(true)
  setListView(false)
}
const changeList = () =>{
  setGridView(false)
  setListView(true)
}

const handleSearch = (e) =>{
  const searchTerm = e.target.value;
  setSearchTerm(searchTerm);

  const filterDara = ProductsData.filter(item =>{
    return item.name.toLowerCase().includes(searchTerm.toLowerCase())
  
  })
 if(filterDara){
  setGridProduct(false)
  setFilerProduct(filterDara)
}
console.log(filterDara)
}

const filterFunc = (catg) =>{
 if(catg === "All"){
  setGridProduct(true)
 } else{
  const updateFilter = ProductsData.filter((x) =>{
    return x.category === catg
    
  })
  setFilerProduct(updateFilter)
  setGridProduct(false)

}
}


const getUniqeData = (data) =>{
  let newVal = data.map((curElm) =>{
    return curElm.category
  })
  return newVal = ["All", ...new Set(newVal)]

}
const getData = getUniqeData(ProductsData)
// console.log(ProductsData)
  return (
   <section className='Product-sec'>
    <div className="main-product-parent">
      <div className='main-filter'>
      <div className="filter-search">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            name="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            autoComplete='off'
          />
        </form>
      </div>
       <div className="catg-name">
        <b className='catg-ti'>Categories</b>
        {
          getData.map((item) =>{
            return(
              <button className={item ? "active" : ""} onClick={() => filterFunc(item)}>{item}</button>
              
              )
            })
          }
          </div>
      </div>
      <section className='product-view-sort'>
           <div className='grid-and-list-main'>
            <div className={gridView ? 'active' : ''}>
            <BsFillGridFill className='icon' onClick={() => changeGrid() } />
            </div>
            <div className={listView ? 'active' : ''}>
            <HiViewList className='icon'  onClick={() => changeList()}/>
            </div>
           </div>
        <div className='product-page'>  

        {
          listView ? gridProduct ? <ListView products={ProductsData} /> : <ListView products={filterProduc} /> : ''
        }
        {
          gridView ?   gridProduct ? <GridView /> :  filterProduc.map((item) =>{
            return(  <Link className='sing-link' to={`/singleproduct/${item.id}`}>
        <div className="card">
        
            <figure className='big-fig'>
                <img src={item.image} alt="name" />
                <figure className='catg'>{item.category}</figure>
            </figure>
            <div className="card-data">
                <h3>{item.name}</h3>
                <p> {<FormatPrice price = {item.price} />}</p>
            </div>
          
        </div>
        </Link>
        )
        }) : ''
        }
        

        
  
        </div>
        <div className='main-product'>
          
        
        </div>
      </section>
    </div>
   </section>
  )
}

export default Products