import React, { useContext } from 'react'
import { ProductProvider } from '../Context/ProductContext'
import FetureSingleProduct from '../components/FetureSingleProduct'
const FeautureProduct = () => {
    const {  ProductsData } = useContext(ProductProvider)
    console.log(ProductsData)
   
    return (
        <section className='fetureProduct-sec'>
            <div className="feture-container">
                <div className="intro-data">
                    Cheak Now
                </div>
                <div className="comon-heading">
                    Our Feature Services
                </div>
                <div className="main-container">
                    {
                    
                        ProductsData.map(item =>(item.featured === true ?  <FetureSingleProduct key={item.id} {...item}  /> : null) )
                    }
                </div>
            </div>

        </section>
    )
}

export default FeautureProduct
