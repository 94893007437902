import React from 'react'

const Contact = () => {
  return (
    <section className='Contact-sec'>
            <h3 className='contact-h3'>Our Contact Page</h3>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1665138.5945685843!2d72.48429709999999!3d35.399702399999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dc67e9beabeaa3%3A0xeba40d2845b38f88!2sSwat%2C%20Khyber%20Pakhtunkhwa%2C%20Pakistan!5e0!3m2!1sen!2s!4v1685872211183!5m2!1sen!2s" title='jk' allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


    <div className="Contact-container">
        <div className="contact-form">
            <form action="https://formspree.io/f/xpzegdzl" method='POST' className='main-form'>
      
                <input className="inp" type="text" placeholder='username' name="user Name" autoComplete='off' required />
                <input className="inp" type="email" placeholder='Email' name='Email' autoComplete='off' required />
                <textarea name="Messege" placeholder='Enter Your Message' required autoComplete='off'></textarea>
                <input className='sub-btn' type="submit" />
            </form>
        </div>
    </div>

    </section>
  )
}

export default Contact
