import React from 'react'
import BellingDetails from '../components/BellingDetails/BellingDetails'
import BellingInp from '../components/BellingInp/BellingInputs'

const Checkout = () => {
  return (
    <>
      {/* ---------------- checkout details  */}
      <div className='mt-5 py-[2rem] px-[0.4rem] ssm:px-[1rem] xsm:px-[2rem]'>
<p className='text-black font-semibold text-[14px]'>Returning Customer  <a href='/' className='text-red-500' >Click Here To Login</a> </p>
<p className='text-black font-semibold text-[14px] my-3'>Have A Copon Code <a href='/' className='text-red-500' >Click Here To Enter Your Code</a> </p>

          <div className='flex flex-col lg:flex-row gap-4 items-center justify-center'>
            <BellingInp />
            <BellingDetails />
          </div>
        </div>
    </>
  )
}

export default Checkout
