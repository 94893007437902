import React from 'react'
import { Link } from 'react-router-dom'
import {FaDiscord, FaInstagram, FaYoutube,} from 'react-icons/fa'

const Footer = () => {
  return (
    <section className='foot-sec'>
  <div className="contact-short">
        <div>
                <h3>Ready To Get Started</h3>     
                     <h3>Talk to us Know </h3>
     </div>      <div>
             <button className="btn">
             <Link  to="/contact">Get Started</Link>
             </button>
        </div>  </div>
   
    <footer>
    <div className="main-footer">
        <div className="about">
            <h3>Rainwater Shah LLC</h3>
            <p>Welcome to Rainwater Shah LLC – an immersive online marketplace designed to elevate your shopping experience.</p>
        </div>
        <div className="subsc">
            <h3>Subscribe To Get Important update</h3>
            <form action="">
                <input className='ft-inp' type="email"   placeholder='Enter Email' required />
                <input type="submit" value="subscribe" className='sub-btn' />
            </form>
        </div>
        {/* <div className="social">
            <h3>Follow Us</h3>
<div className='pi-icon'>

            <div>
                <FaDiscord  className='ico' />
            </div>
            <div>
                <FaInstagram  className='ico'/>
            </div>
            <div>
                <FaYoutube className='ico' />
            </div>
</div>
        </div> */}
        <div className='fot-contact'>
            <h3>Call Us</h3>
          <a href="tel:+92 321 5813309">+92 321 5813309</a>
        </div>
   

    </div>
    <div>

        <hr className='hr' />
    </div>
    <div className='bot-foot'>
        <div>
           <p>&copy; {new Date().getFullYear()} Code With Zubii All Right Reserved</p>
        </div>
        <div className='last-div'>
            <p>Privacy Policy</p>
            <p>Terms & Condition</p>
        </div>
    </div>
</footer>
</section>
    // <section className='foot-sec'>

    //   
      
    // </section>
  )
}

export default Footer
