import  { useEffect } from 'react'
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'


const overscrollOptions = {
    enable: true,
    effect: 'bounce',
    damping: 0.17,
    maxOverscroll: 150,
    glowColor: '#fff',
  };
let options = {
    damping: 0.07,
    Plugins: {
        overscroll: {...overscrollOptions}
    }
}

const SmoothSroll = () => {
    useEffect(() =>{
        Scrollbar.use(OverscrollPlugin)
        Scrollbar.init(document.body, options);

        return () =>{
            if(Scrollbar) Scrollbar.destroy(document.body)
        }
    },[])

  return null
}

export default SmoothSroll
