import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import PageNavigation from '../components/PageNavigation';
import FormatPrice from '../Helper/FormatPrice';
import {TbTruckDelivery} from 'react-icons/tb'
import MyProductImg from '../components/MyProductImg';
import Start from '../components/Start';
import { CartProvider } from '../Context/CartContext';
import { productData } from '../data';
// import { ProductProvider } from '../Context/ProductContext'

const SingleProduct = () => {
    const [singProduct,setSingProduct] = useState([])
    
    const { addToCart, } = useContext(CartProvider)
    const {id} = useParams();

    useEffect(() =>{
           const getSingleProduct = async () =>{
            const Data = await productData;

            const fil = Data.filter((i) =>{
                return i.id === id;
            })

            console.log(fil)
            setSingProduct(fil);
        }
        getSingleProduct()
    },[id])

    console.log(singProduct) 
        



return (
<>
      {
          singProduct.map((i) =>{
              return (
                  <section className='singleProduct-sec'>
    <PageNavigation title={i.name} />

    <div className="sing-container">
                <div className="single-product-main">
                <div className="single-product-image">
                    <MyProductImg imgs={i.image} />
                </div>
                <div className="single-product-data">
                    <h2 className='name'>{i.name}</h2>
                  {/* <Start stars = {stars} reviews = {reviews} /> */}
                 
                    <del>
                    <p className='Dis-Price'><FormatPrice price={i.price *2500} /></p>
                    </del>
                    <p className='RealPrice'><FormatPrice price={i.price} /></p>
                    <p className='desc'>{i.description}</p>
               
                    <hr />
                    <div className="p-di">
                        <p>Avalible:
                            <span>{i.stock > 0 ? 'in Stock':'Not Avalible'}</span>
    
                        </p>
                        <p>ID : <span>{id}</span></p>
                        <p>Brand : <span>{i.company}</span></p>
                    </div>
                    <hr className='tora-barahHr' />
                    <div>
                    
                  
      
                    </div>
                    {i.stock > 0 &&  <Link to={'/cart'} className='addToCart' onClick={() => addToCart(singProduct,id,)}>Add To Cart</Link> }
                   
                </div>
                <hr />
            </div>
    </div>
</section>
            )
        })
      }
</>
  )
}

export default SingleProduct
