import React, { useContext,  useState } from 'react'
import { Link } from 'react-router-dom'
import {CgMenu,CgClose} from 'react-icons/cg'
import {AiOutlineShoppingCart} from 'react-icons/ai'
import { CartProvider } from '../Context/CartContext'
import { useAuth0 } from '@auth0/auth0-react'
import logo from '../assets/llclogo.png'

const Header = () => {
    const { loginWithRedirect,logout,isAuthenticated,user } = useAuth0();
  
    const [mobile,setMobile] = useState(false)
    const [userData,setUserData] = useState(false)
   
  
const { itemAmount, } = useContext(CartProvider)

      
    return(
    <header>
  
            <nav>
       <Link to={'/'} className='Logo'> <img src={logo} className='w-[140px] h-auto object-cover' alt='logo' /> </Link>
            <ul  className={mobile ? 'nav-active' : 'ul'} onClick={() => setMobile(false)}>
                    <li>
                        <Link to={'/'} >Home</Link>
                    </li>
                    <li>
                        <Link to={'/about'} >About</Link>
                    </li>
                    <li>
                        <Link to={'/contact'} >Contact</Link>
                    </li>
                    <li>
                        <Link to={'/products'} >Products</Link>
                    </li>
                  <li className='hide-for-mobil' >
                  {
                        isAuthenticated ? 
                        <div className='user-main'>
                        <img  onClick={() => setUserData(!userData)} className='user-profile' src={user.picture} alt={user.name} />
                            <div className={userData ? 'user-data active-user-data' : 'user-data'}>
                                <div onClick={() => setUserData(true)} className="profile">
                            <img className='image-profile' src={user.picture} alt={user.name} />

                                </div>
                                <h2 className='user-name'>{user.name}</h2>
                                <p className='user-email'>{user.email}</p>
                                <Link className='Login-Btn logout' onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}> Log Out </Link>
                            </div>
                        </div>
                        : 
                    <li>
                             
                        <Link className='Login-Btn'  onClick={() => loginWithRedirect()}>Log In</Link>
                    </li>

                    }
                  </li>
                   
                    <li className='Cart-li'>
                        <Link to={'/cart'} ><AiOutlineShoppingCart className='Cart-Icon'/>
                        <span>{itemAmount}</span>
                        </Link>
                    </li>
                   
                </ul>
                
                <div className='navicon-div' onClick={() => setMobile(!mobile)}>
                {mobile ? <CgClose className='Close-Icon'/> : <CgMenu className='Menu-Icon'/>}
             
                
            </div>
            </nav>
            <div className='user-mobile'>
                {
                        isAuthenticated ? 
                        <div className='user-main'>
                        <img onClick={() => setUserData(!userData)} className='image-move user-profile' src={user.picture} alt={user.name} />
                            <div className={userData ? 'user-data active-user-data' : 'user-data'}>
                                <div onClick={() => setUserData(true)} className="profile">
                            <img className='image-profile' src={user.picture} alt={user.name} />

                                </div>
                                <h2 className='user-name'>{user.name}</h2>
                                <p className='user-email'>{user.email}</p>
                                <Link className='Login-Btn logout' onClick={()  => logout({ logoutParams: { returnTo: window.location.origin } })}> Log Out </Link>
                            </div>
                        </div>
                        : 
                   
                             
                       <div className="login-mobile">
                         <Link className='Login-Btn chng'  onClick={() => loginWithRedirect()}>Log In</Link>
                   
                       </div>
                    }
                </div> 
           
    </header>
    )
}

export default Header
