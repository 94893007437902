import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
 <section className='error-sec'>
    <div>
        <h2>404</h2>
        <p>Page Not Found</p>
        <Link to={'/'} className='btn'>Go Back Home</Link>
    </div>
 </section>
  )
}

export default ErrorPage
