  "use client"

import React from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import CreditCardFeilds from "../CreditCardFeilds"
import {useState} from 'react'
import { Link } from 'react-router-dom'
const BellingDetails = () => {
  const [cardClose,setCardClose] = useState(false);
  return (
    <form className='py-[2rem] bg-[#f2f2f2] p-[0.3rem] ssm:px-[1rem] w-full lg:w-[50%]'>
      <h2 className='text-center font-semibold text-[24px] my-3 '>Your Order</h2>

      {/* detail product show  */}
      <div className='w-full bg-white px-[10px] ssm:px-[30px] py-[50px]'>
        <div style={{borderBottom:'1px solid #dbd0d0'}} className=' flex items-center justify-between '>
          <p className='font-semibold text-[12px] xsm:text-[18px]'>PRODUCT</p>
          <p className='font-semibold text-[12px] xsm:text-[18px]'>SUBTOTAL</p>
        </div>

        {/* ----- main product  */}

    

    {/* --- sub total--  */}

    <div style={{borderBottom:'1px solid #dbd0d0'}} className='flex my-3 py-2 justify-between items-center'>
    <p className='text-black font-semibold text-[12px] xsm:text-[16px]'>SubTotal</p>
    <p className='text-red-600 font-bold text-[12px] xsm:text-[16px]'>$6565</p>

    </div>


    {/* ----Shipping -- /  */}
    <div style={{borderBottom:'1px solid #dbd0d0'}} className='flex my-3 py-2 justify-between items-center'>
    <p className='text-black font-semibold text-[12px] xsm:text-[16px]'>Shipping</p>
   <div className='flex flex-col items-end justify-end'>
    <div className='flex items-center gap-2'>
    <p className='font-normal  text-[12px] xsm:text-[16px]'>Plat Rate <span className='font-bold text-red-500 text-[12px] xsm:text-[16px]'>$34</span> </p>
      <input type='radio' name='price' />
    </div>
    <div className='flex items-center gap-2'>
      <p className='font-normal  text-[12px] xsm:text-[16px]'>Local Pickup <span className='font-bold text-red-500'>$34</span> </p>
      <input type='radio' name='price' />
    </div>
   </div>

    </div>


{/* ---------------- total  */}
    <div style={{borderBottom:'1px solid #dbd0d0'}} className='flex my-3 py-2 justify-between items-center'>
    <p className='text-black font-semibold text-[12px] xsm:text-[16px]'>Total</p>
    <p className='text-red-600 font-bold text-[12px] xsm:text-[16px]'>$68565</p>

    </div>
      </div>

{/* --- payment detail  */}
    <p className='font-normal my-4 text-[10px] xsm:text-[14px]'> <input onClick={() => setCardClose(true)}  name="pay" type='radio' /> Direct Bank Transfer </p>


 {cardClose ? <CreditCardFeilds /> : " "}

      

      <p className='font-normal my-4 text-[10px] xsm:text-[14px]'> <input onClick={() => setCardClose(false)} name="pay" type='radio' /> Cash Payment </p>
      <p className='font-normal my-4 text-[10px] xsm:text-[14px]'> <input name="pay" type='radio' /> Cash On Delivary </p>

      <p className='font-normal my-4 text-[10px] xsm:text-[14px]'> <input onClick={() => setCardClose(false)}  name="pay" type='radio' /> Cash On Paypal </p>


{/* <!-- ------- descr --  --> */}
<div style={{borderBottom:'1px solid #dbd0d0'}} className='text-[#a39595] text-[10px] xsm:text-[14px] py-5' >
    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorem, aspernatur. Adipisci sequi non ipsam labore voluptas aut quis incidunt blanditiis voluptate! Rem, similique libero esse consequatur ratione eius ipsa iusto. <span className="text-black font-bold">Privacy Policy</span>
      </div>

      {/* ----- ihave read --  */}

      <p className='font-normal text-[12px] xsm:text-[14px] text-[#3f3737] my-5'> <input name="c" type='checkbox' /> I have read all and agree to the website <span className='text-black font-bold'>terms and conditions <span className='text-red-600'>*</span></span> </p>

<Link to={'/orderdone'} className='border-none text-center my-6 w-full py-[10px] font-semibold bg-[#ffcc00] hover:bg-[#ffde58] transition-all inline-block ease-in duration-700 text-white' >PLACE ORDER</Link>

    </form>
  )
}

export default BellingDetails