import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ProductContext from './Context/ProductContext';
import CartContext from './Context/CartContext';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Auth0Provider
    domain="dev-v63uweomv8z5acaq.us.auth0.com"
    clientId="8IOb6jRmCiLWuvXbi1lYDxbPstvuxrMf"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >

  <ProductContext>
    <CartContext>




    
    <App />

  
    </CartContext>
  </ProductContext>
  </Auth0Provider>
);

