import React from 'react'
import FormatPrice from '../Helper/FormatPrice';
import { Link } from 'react-router-dom';


const ListView = ({products}) => {
  
  return (
   <section>
    <div className='list-frist-div'>
        {products.map((curElm) =>{
            const {id,name,image,price,description} = curElm;
            return <div className="card-two-coloum">

                  <Link to={`/singleproduct/${id}`}>
                      <figure className='big-figg'>
                    <img src={image} alt="" />
                </figure>
                  </Link>
                <div className="List-card-data">
                    <h3>{name}</h3>
                    <p> {<FormatPrice price = {price} />}</p>
                    <p>{description.slice(0, 90)}</p>
                </div>
            </div>
        })}
    </div>
   </section>
  )
}

export default ListView