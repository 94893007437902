
import React, { useEffect, useState } from 'react'
import { createContext } from "react";
import { productData } from '../data';



export const ProductProvider = createContext()

const ProductContext = ({children}) => {

  const [ProductsData,setProdutsData] = useState([])
    // const API = "https://api.pujakaitem.com/api/products"; 
    
    
    
    
   

    useEffect(() =>{
      const fetchData = async() =>{
        // const res = await fetch(API);
        // const Data = await res.json();
        const Data = await productData;

        setProdutsData(Data)
        console.log(Data)
      }
      fetchData()
    },[])
    
ProductsData.map((item) =>{
   return item.quantity = 0
})
  return (
   <ProductProvider.Provider value={{ProductsData}}>
    {children}
   </ProductProvider.Provider>
  )
}

export default ProductContext
