import FormatPrice from '../Helper/FormatPrice'
import { useContext } from 'react';
import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa'
import { CartProvider } from '../Context/CartContext';


const Cart = () => {


  
const { cart,total,removeFromCart,clearCart,decreaseAmount,increaseAmount } = useContext(CartProvider)

console.log(cart)

      

  if (cart.length < 1){
    return <h1 style={{textAlign:"center",padding:"30px"}}>Cart is empty</h1>
  } else{
    return (
      <section style={{overflowX:'hidden'}} className='show-sec'>
   {cart.map((item) =>{
    return(
      
        <div className="grid">
        {/* console.log(item.image[0].url) */}
   

              <div className='product-img'>
            <img src={item[0].image[0]} alt={item[0].titlr} />
          </div>
     
          <div>
            Price:
           <FormatPrice  price = {item[0].price} />
          </div>
          <div className='qty'>
          <button onClick={() =>  decreaseAmount(item[0].id) }>
        <FaMinus />
    </button>
    <div className="amount">{item.quantity}</div>
    <button onClick={() => increaseAmount(item[0].id)} >
        <FaPlus />

    </button>
    {/* <CartAmountToggle amount={item.amount}
    // setDecrease = {() => setDecrease(item.id)}
    // setIncrease = {() => setIncrease(item.id)} />
  </div> */}
          </div>
  
          <div className="subtotal" >
            SubTotal:
          <FormatPrice  price = {item[0].price * item.quantity} />
          </div>
          <div className="Remove" onClick={() => removeFromCart(item[0].id)}>
            <FaTrash />
          </div>

          
        
    </div>
)
  })}
   

        
  

     <div className='Two-btn'>
<Link to={'/products'}>Contineous Shopping</Link>
<Link onClick={clearCart}  className='Clear-cart'>Clear Cart</Link>

</div>
<div className='Totl-main' style={{justifyContent:'end'}}>
  <div >
    <p>Total price : {total} </p>
    <p>Shipping fee <FormatPrice price={500}/> </p>
    <h4>Order total:<FormatPrice price={total + 500} /></h4>
    <Link to={'/checkout'} className='checkoutbtn'>CheckOut</Link>
  </div>
</div>

   </section>
  )
}
}

export default Cart