export const productData = [
    // ------ catg 1 --- 
    {
        id: "1",
        name: "Stanley Quencher H2.0 FlowState Stainless Steel Vacuum Insulated Tumbler",
        company: "rainwater shah LLC",
        price: 35.00,
        image: ["https://m.media-amazon.com/images/I/515dvIDRjwL.__AC_SX300_SY300_QL70_FMwebp_.jpg"],
        
        description: "YOUR DREAM TUMBLER: Whichever way your day flows, the H2.0 FlowState tumbler keeps you refreshed with fewer refills. Double wall vacuum insulation means drinks stay cold, iced or hot for hours. Choose between our 14oz, 20oz, 30oz,40oz and 64oz options depending on your hydration needs. The narrow base on all sizes (except 64oz) fits just about any car cup holder, keeping it right by your side. ADVANCED LID CONSTRUCTION: Whether you prefer small sips or maximum thirst quenching, Stanley has developed an advanced FlowState lid, featuring a rotating cover with three positions: a straw opening designed to resist splashes with a seal that holds the reusable straw in place, a drink opening, and a full-cover top for added leak resistance. ",

        category: "Home And Kitchen",
        featured: false,
        stock: 10,
    },
    {
        id: "2",
        name: "Fullstar Vegetable Chopper - Spiralizer Vegetable Slicer - Slicer Dicer Cutter",
        company: "rainwater shah LLC",
        price: 39.99,
        image: ["https://m.media-amazon.com/images/I/81m5GFr6aeL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/81+BlpSfStL._AC_SL1500_.jpg" ," https://m.media-amazon.com/images/I/81wjqUwibgL._AC_SL1500_.jpg "," https://m.media-amazon.com/images/I/81XT5245vbL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/91h6zizVmaL._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/81YO9pw5wYL._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/81fKUUqBGIL._AC_SL1500_.jpg "],
        description: "The Original Pro Chopper - Our superstar veggie slicer has been creating waves on TikTok! Simple to use, set up, and clean, save time and energy during meal prep with our OG vegetable chopper. Chop, slice, and dice your ingredients like a seasoned chef! Shorten Prep Time - Speed up cooking time while adding fresh, nutritious fruits and vegetables to every dish with our top selling vegetable chopper. Includes 4 blades of unmatched quality: fine and medium dicer, julienne, and ribbon spiralizer. 5-Star Design - With a soft grip handle made of long-lasting TPU, you'll enjoy more control over your chopping motions. The non-skid rubberized bottom of this BPA-free veggie chopper will keep it firmly in place on any surface. ",
        category: "Home And Kitchen",
        featured: false,
        stock: 10,
    },
    {
        id: "3",
        name: "The Original Gorilla Grip Heavy Duty Stainless Steel Smooth Edge Manual Hand Held",
        company: "rainwater shah LLC",
        price: 20.99,
        image: ["https://m.media-amazon.com/images/I/61YwDNLHehL.__AC_SX300_SY300_QL70_FMwebp_.jpg","https://m.media-amazon.com/images/I/51n8RhmsIKL._AC_SX679_.jpg ","https://m.media-amazon.com/images/I/51PhJy2JcqL._AC_SX679_.jpg","https://m.media-amazon.com/images/I/51FbHCLYV8L._AC_SX679_.jpg"],
        description: "STRONG AND POWERFUL STAINLESS STEEL BLADE: finally, a more powerful can opener; sharp and durable 100 percent stainless steel cutting wheel will easily puncture all types of cans; rust resistant for long lasting use. HEAVY DUTY CONSTRUCTION FOR EASIER OPENING: effortless can opening is here; the can opener features an extra large turn knob that allows you to easily open the can with little effort, so you work with the motion instead of against it; also includes stainless steel construction for a seamless cut and oversized handles that make for an easier grip. SOFT TOUCH GRIP. ",
        category: "Home And Kitchen",
        featured: false,
        stock: 6,
    },
    {
        id: "4",
        name: "Melitta Super Premium No. 4 Coffee Paper Filter, Natural Brown, 100 Count ",
        company: "rainwater shah LLC",
        price: 4.99,
        image: ["https://m.media-amazon.com/images/I/715SZVK7tNL._AC_SL1500_.jpg"," https://m.media-amazon.com/images/I/71FhFPw7YJL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/61D29kedv2L._AC_SL1500_.jpg"," https://m.media-amazon.com/images/I/61dOMg-18sL._AC_.jpg"," https://m.media-amazon.com/images/I/61HDfDXnCGL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/81WBG4BK7oL._AC_SL1500_.jpg"," https://m.media-amazon.com/images/I/71RhTVVTjJL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/51Gv6B5UteL._AC_US100_AA50_.jpg"],
        description: "Cone Coffee Filters: Premium cone coffee filters will fit all 8 to 12 cup drip coffee makers with a conical shaped filter; Also fits most pour-over coffeemakers that need #4 coffee filters; Traps more bitter oils for a better cup of coffee. Advanced Design: Our coffee filters feature Microfine Flavor Enhancing perforations that allow the full coffee flavor to filter through and a double crimped filter design to help protect against bursting. Number One Coffee Filter Brand in the World: Our cone, basket and single serve filters are all designed to brew rich, flavorful coffee.",
        category: "Home And Kitchen",
        featured: false,
        stock: 6,
    },
    {
        id: "5",
        name: "Rubbermaid Brilliance BPA Free Food Storage Containers with Lids, Set of 5 (3.2 Cup) ",
        company: "rainwater shah LLC",
        price: 38.99,
        image: ["https://m.media-amazon.com/images/I/81pJ9l2Tx-L._AC_SL1500_.jpg"," https://m.media-amazon.com/images/I/618u+lbQihL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/71tR7WNQK7L._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/611SQrFY18L._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/811Y0MMNRTL._AC_SL1500_.jpg"],
        description: "Rubbermaid Brilliance 100% leak-proof food storage containers feature BPA-free Tritan lids and bases with 360-degree clarity; airtight seal and secure latches prevent spills and leaks and help keep food fresh and secure.  Perfect for a variety of occasions and events—like lunches, snacks, meal prep, parties, holidays, and more. Crystal-clear containers are clear like glass so you can always see what’s inside, but are light and easy to carry.",
        category: "Home And Kitchen",
        featured: false,
        stock: 10,
    },
    {
        id: "6",
        name: "HELIMIX 2.0 Vortex Blender Shaker Bottle Holds upto 28oz",
        company: "rainwater shah LLC",
        price: 28.99,
        image: ["https://m.media-amazon.com/images/I/61+JCuI0bfL._AC_SY879_.jpg","https://m.media-amazon.com/images/I/61rzj24FJxL._AC_SX679_.jpg", "https://m.media-amazon.com/images/I/61cD3MqOvvL._AC_SX679_.jpg"],
        description: "🚫 NO SHAKING ACCESSORIES NEEDED - Forget the days of looking for your wire shaker ball and buying shaker ball replacements because the Helimix uses a patented vortex blender design that doesn't require anything but a few good shakes. This also means you don't have to worry about a clogged or dirty shaker balls ruining your protein shake. 🏆 SUPERIOR SUPPLEMENT MIXING - Helimix has been rigorously tested against other mixer cups to ensure you get the best shaker bottle on the market.",
        category: "Home And Kitchen",
        featured: false,
        stock: 10,
    },
    // ------------- catg 2 ------ 
    {
        id: "7",
        name: "COSRX Snail Mucin 96% Power Repairing Essence 3.38 fl.oz 100ml,",
        company: "rainwater shah LLC",
        price: 23.99,
        image: ["https://m.media-amazon.com/images/I/61p-wtpDraL._SL1500_.jpg"," https://m.media-amazon.com/images/G/01/ui/loadIndicators/loading-large_labeled._CB485921664_.gif"," https://m.media-amazon.com/images/I/51K59a5cRjL._SL1500_.jpg"," https://m.media-amazon.com/images/I/61SgB5IlNPL._SL1500_.jpg"," https://m.media-amazon.com/images/I/61PlxFCrDbL._SL1500_.jpg"],
        description: "The Real Snail Essence: Formulated with 96.3% Snail Secretion Filtrate, this essence repairs and rejuvenates the skin from dryness and aging. It improves skin vitality by reducing dullness and soothing dehydrated skin. Simple Yet Effective Light-weight Essence: A lightweight essence which fastly absorbs into the skin and gives you a natural and healthy glow.",
        featured: true,
        stock: 10,
    },
    {
        id: "8",
        name: "Mielle Organics Rosemary Mint Scalp & Hair Strengthening Oil for All Hair Types, 2 Ounce ",
        company: "rainwater shah LLC",
        price: 127.00,
        image: ["https://m.media-amazon.com/images/I/61NFkX7td8L._SL1500_.jpg", "https://m.media-amazon.com/images/I/81ga57qPUOL._SL1500_.jpg"," https://m.media-amazon.com/images/I/41CnKN8QpqL.jpg", "https://m.media-amazon.com/images/I/71WXrTVM8oL._SL1500_.jpg"," https://m.media-amazon.com/images/I/41Kfd9j0DYL.jpg", "https://m.media-amazon.com/images/I/61uueBpmRiL._SL1500_.jpg"," https://m.media-amazon.com/images/I/71g6Ooyk1DL._SL1500_.jpg"],
        description: "NOURISH & STRENGTHEN: Our nutrient-rich, biotin-infused treatment can be used as part of your everyday hair care routine or as an intensive deep treatment to prevent damage for strong, lustrous hair with a fresh, invigorating scent. SMOOTH SPLIT ENDS: This lightweight treatment nourishes split ends for a sleek look; Apply to the ends of hair before you shampoo to soothe and smooth for a healthy, shiny finish that can be air-dried or styled as desired.",
        category: "Beauty And Personal Care",
        featured: false,
        stock: 10,
    },
    {
        id: "9",
        name: "Dove Body Wash with Pump Deep Moisture For Dry Skin Moisturizing",
        company: "rainwater shah LLC",
        price: 12.09,
        image: ["https://m.media-amazon.com/images/I/81AKrbl69YL._SL1500_.jpg","https://m.media-amazon.com/images/I/71NoMbII9dL._SL1500_.jpg ", "https://m.media-amazon.com/images/I/81XFpQ0petL._SL1500_.jpg"],
        description: "24 HR RENEWING MICROMOISTURE: Dove Deep Moisture Body Wash gently cleanses and deeply moisturizes the skin, leaving it renewed and healthy-looking for 24 hours.FOR SOFT, SMOOTH SKIN: Our deeply moisturizing body wash, formulated with millions of MicroMoisture droplets, leaves skin soft as silk. NOURISHES THE DRIEST SKIN: Dove Deep Moisture Body Wash for dry skin actively regenerates skin’s natural moisture and deeply nourishes the driest skin,THOUGHTFULLY MADE: This sulfate-free body wash is vegan and Dove is PETA approved, meaning we do not test on animals, anywhere in the world. Our body wash is packaged in 100% recycled plastic bottles, so you can feel good about switching to Dove. No parabens. ",
        category: "Beauty And Personal Care",
        featured: false,
        stock: 12,
    },
    {
        id: "10",
        name: "FHI HEAT UNbrush Wet & Dry Vented Detangling Hair Brush, Cherry Blossom",
        company: "rainwater shah LLC",
        price: 18.09,
        image: ["https://m.media-amazon.com/images/I/61iB1kaDQhL._SL1500_.jpg","https://m.media-amazon.com/images/I/61jOOM-0vpL._SL1000_.jpg","https://m.media-amazon.com/images/I/51qAncQpg6L._SL1500_.jpg", "https://m.media-amazon.com/images/I/61EywQ1h65L._SL1000_.jpg"],
        description: "Unique vented cushion reduces styling time. DuoFlex Anti-Static Bristles work like magic. Gentle tangle release reduces breakage and split ends. Smoothly detangles even the most stubborn hair. Works equally great on wet or dry hair.",
        category: "Beauty And Personal Care",
        featured: false,
        stock: 6,
    },
    {
        id: "11",
        name: "REVLON One-Step Volumizer Original 1.0 Hair Dryer and Hot Air Brush, Black ",
        company: "rainwater shah LLC",
        price: 33.99,
        image: ["https://m.media-amazon.com/images/I/61Kn597KGkL._AC_SL1500_.jpg","https://m.media-amazon.com/images/I/61Kn597KGkL._AC_SL1500_.jpg"," https://m.media-amazon.com/images/I/71chlvixduL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/81Ys7D68BrL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/81WZQLsGS5L._AC_SL1500_.jpg"],
        description: 'VOTED “BEST BLOW-DRY BRUSH: Simplify your getting-ready routine with this fan favorite. TWO-IN-ONE TOOL: Dry and style in up to half the time* and get that just-left-the-salon feeling without leaving home. 2.8” BRUSH: Styles all hair types and lengths, its wide surface area is great for long hair. SALON-STYLE BLOWOUTS: Get shine, tons of volume and a smooth finish, plus create soft waves and body. THREE HEAT/SPEED SETTINGS: Choose from cool, low or high to dry and style with up to 50% less heat exposure for less damage.',
        category: "Beauty And Personal Care",
        featured: false,
        stock: 17,
    },
    {
        id: "12",
        name: "L’Oreal Paris Makeup Voluminous Original Volume Building Mascara, Carbon Black, 0.26 Fl Oz",
        company: "rainwater shah LLC",
        price: 9.99,
        image: ["https://m.media-amazon.com/images/I/51FHYea5e3L._SL1500_.jpg", "https://m.media-amazon.com/images/I/71GXbEzNPmL._SL1051_.jpg", "https://m.media-amazon.com/images/I/812v37Ap4TL._SL1500_.jpg"," https://m.media-amazon.com/images/I/710EjZmAGIL._SL1500_.jpg"],
        description: "Voluminous Mascara is uniquely formulated to resist clumping, soften and build lashes up to 5X their natural thickness; The Volume Maximizing Brush thickens lashes evenly and smoothly; Suitable for sensitive eyes and contact lens wearers. L'Oreal Paris Mascara: Get the long, full eyelashes you love with our best mascaras and primers; Choose from our innovative volumizing formulas and variety of brushes. Create you perfect eye makeup look with our collection of Voluminous mascaras, achieve sleek lines with smudge proof eyeliner. ",
        category: "Beauty And Personal Care",
        featured: false,
        stock: 17,
    },
    // ------------- catg 3 ------ 
    
    {
        id: "14",
        name: "Dealusy 100 Pack 16 oz Disposable Coffee Cups with Lids and Sleeves.",
        company: "rainwater shah LLC",
        price: 24.99,
        image: ["https://m.media-amazon.com/images/I/71wPcM38wLL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/61PnwVQHONL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/616CDrMrQhL._AC_SL1500_.jpg"],
        description: "【Sturdy & Heat Free】: Made of premium thick paper, Dealusy 16 oz coffee cups with lids and sleeves give you a comfortable grip of your coffee, perfect to go coffee cups with lids for everyday use. Leak Free】: Extra thick and sturdy paper coffee cups 16 oz with poly-coated lining ensure 100% leak free, our odor-free disposable paper coffee cups helps you enjoy you caffeine and boost anytime, anywhere. 【Value Package】: This bulk coffee cups package includes 100 cups, 100 lids and 100 sleeves, perfect for coffee bar accessories. ",
        category: "Sports And Outdoors",
        featured: false,
        stock: 21,
    },
    {
        id: "15",
        name: "Selkirk Pro S1 Ball | Crack-Resistant.",
        company: "rainwater shah LLC",
        price: 12.29,
        image: ["https://m.media-amazon.com/images/I/61-RMpwcdXL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/71Jpw78EYHL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/71Sv2s7FMfL._AC_SL1500_.jpg"],
        description: "【 Cutting-Edge Design 】 The Pro S1 Pickleball Ball introduces a revolutionary cutting-edge patented design that guarantees consistent flight, bounce, and unmatched durability. Backed by a 1-Year No-Crack Warranty, it establishes a new standard in pickleball ball technology, catering to players of all levels. 【 Precision Craftsmanship 】 Unlock unmatched consistency with the Pro S1, empowering players with a precise feel off the paddle face. Gain the confidence to execute perfect shots at the right time during every match. Ideal for both indoor and outdoor play, this ball ensures a reliable performance that will enhance your pickleball experience. 【 Exceptional Durability 】 Years of development have led to the Pro S1's seamless rotomolding technology. ",
        category: "Sports And Outdoors",
        featured: false,
        stock: 6,
    },
    {
        id: "16",
        name: "TIMOOU Wrist & Ankle Weights for Women Men, ",
        company: "rainwater shah LLC",
        price: 23.84,
        image: ["https://m.media-amazon.com/images/I/61Epfyj67HL._AC_SL1500_.jpg","https://m.media-amazon.com/images/I/71Zzf2vQp8L._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/813K33Uin+L._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/71D0gqC9ynL._AC_SL1500_.jpg"],
        description: "STAND OUT: Designed to be clean and secure,These versatile adjustable wrist weights fit seamlessly into your fitness and exercise routine.Add constant resistance to your workout (or walk) to increase your calorie-burn without the jump squats. These weighted bracelet help you get the most out of your daily movement. ONE SIZE FITS ALL: The surface of the wrist weight bracelets is made of premium & soft silicone material, which Prevents friction from causing damage to the skin.The waterproof and sweat-proof design makes it easy to clean and odor free.",
        category: "Sports And Outdoors",
        featured: false,
        stock: 6,
    },
    {
        id: "17",
        name: "Heated Socks for Men Women,5000mAh Rechargeable Electric Heated Socks",
        company: "rainwater shah LLC",
        price: 69.99,

        image: ["https://m.media-amazon.com/images/I/71VRLwUgO2L._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/81AbZneoOsL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/71pSlhSz50L._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/713ci2-0APL._AC_SL1500_.jpg"],
        description: "【New Updated Smart Phone APP Control】 - Men's and women's heated socks use the latest technology upgraded version of one-button smart controller, no registration is required, after you download the iWarmU APP, through the APP, you can adjust the temperature, timed heating, power on/off. In addition, you can also use battery control directly, (just press the On / Off button for 3 seconds, you can feel the heat around your toes and soles in 8 seconds). 360°Loop Heating Socks】 - With 3D loop invisible heating wires that are interspersed in the fabric and cover the entire toe area, they can quickly heat up cold feet and keep them warm for a long time, which can promote blood circulation.",
        category: "Sports And Outdoors",
        featured: false,
        stock: 14,
    },
    {
        id: "18",
        name: "Exercise Bands Resistance Loop Multipurpose.",
        company: "rainwater shah LLC",
        price: 12.10,
        image: ["https://m.media-amazon.com/images/I/61z1xuIvBLL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/616VawJvP7L._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/711dITOlzQL._AC_SL1500_.jpg"],
        description: " 🌎Workout Anywhere with hperycal: Seamlessly integrated into any workout regime, our resistance bands work with general exercises, stretching, strength training, or power weight programs. The hperycal experience transcends boundaries, transform any corner into your personal gym. Never miss a workout with our carry bag designed for easy mobility.【360°Loop Heating Socks】 - With 3D loop invisible heating wires that are interspersed in the fabric and cover the entire toe area, they can quickly heat up cold feet and keep them warm for a long time, which can promote blood circulation. ",
        category: "Sports And Outdoors",
        featured: false,
        stock: 10,
    },
    // ------------- catg 4 ------ 
    {
        id: "19",
        name: "Fitbit Charge 6 Fitness Tracker with Heart Rate, GPS",
        company: "rainwater shah LLC",
        price: 129.96,
        image:[ "https://m.media-amazon.com/images/I/61wn2jfhBkL._AC_SL1500_.jpg"," https://m.media-amazon.com/images/I/51quHg2iioL._AC_SL1500_.jpg"," https://m.media-amazon.com/images/I/81WxZgQlR0L._AC_SL1500_.jpg"," https://m.media-amazon.com/images/I/81lf7L9u2DL._AC_SL1500_.jpg"],
        description: "Move more: Heart Rate on Equipment via Bluetooth, 40+ Exercise Modes, Built-in GPS, Active Zone Minutes, Daily Readiness Score, 24/7 Heart Rate, All-day Activity Tracking, Cardio Fitness Level, Automatic Exercise Tracking, Workout Intensity Map and Reminders to Move. Comfortably Connected: Youtube Music Controls, Google Maps, Google Wallet, Call, Text and Smartphone App Notifications, Vibrant Colour Touchscreen with Customizable Clock Faces, Timer and Stopwatch.",
        category: "Sports And Outdoors",
        featured: true,
        stock: 2,
    },
    {
        id: "20",
        name: "Sticky Notes, 12 Pads, 3x3 inches, Colorful Self-Stick Note Pads",
        company: "rainwater shah LLC",
        price: 12.90,
        image: ["https://m.media-amazon.com/images/I/514s6i+uggL._AC_.jpg" , "https://m.media-amazon.com/images/I/510ZgMXAhIL._AC_.jpg" ,"https://m.media-amazon.com/images/I/51QwyvrHphL._AC_.jpg" ," https://m.media-amazon.com/images/I/61LIDQ-YDyL._AC_.jpg "," https://m.media-amazon.com/images/I/616UH6UtBML._AC_.jpg" , "https://m.media-amazon.com/images/I/61SWSBhNVJL._AC_.jpg "," https://m.media-amazon.com/images/I/511OCoFS9fL._AC_.jpg"],
        description: "Colorful Efficiency in Abundance - Our sticky notes Set includes 12 pads, each with 50 sheets, bringing you a total of 600 vibrant and versatile notes. These vibrant and versatile sticky notes are ready to help you stay organized and efficient in every endeavor. Boost Work Efficiency - Elevate your workday with our vibrant sticky note pads. Ideal for quick reminders, marking key points, and effortless task organization, these colorful companions bring a touch of creativity to your workplace. Brighten Your Study Journey - Enhance your learning journey with our dynamic sticky notes. Whether it's textbooks.",
        category: "Office Products",
        featured: false,
        stock: 18,
    },
    {
        id: "21",
        name: "Nicecho Expanding File Folder, 7 Pockets Accordion File Organizer",
        company: "rainwater shah LLC",
        price: 13.99,
        image: ["https://m.media-amazon.com/images/I/713Uzo6LH6L._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/81+hUs2eNfL._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/81WZRUMsebL._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/71lex53FtxL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/713FTpv1l6L._AC_SL1500_.jpg "," https://m.media-amazon.com/images/I/71MdboPuFqL._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/817ROMwSL7L._AC_SL1500_.jpg "],
        description: "【HIGH CAPACITY】Product size is 12.9”×9.5”×0.6”. 7 individual pockets, maximum 250 sheets of paper, it is large enough to keep all of your important documents securely, help to classify and find your files effectively. 【HIGH QUALITY MATERIAL】Made of premium environmentally friendly PP material, Acid-free, PVC-free, and archival quality for safe storage.",
        category: "Office Products",
        featured: false,
        stock: 12,
    },
    
    {
        id: "23",
        name: "Hiipoo Sublimation Ink 522 Refilled Bottles. ",
        company: "rainwater shah LLC",
        price: 22.99,
        image: ["https://m.media-amazon.com/images/I/81R5obbspKL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/71bmcKoo9aL._AC_SL1300_.jpg" , "https://m.media-amazon.com/images/I/71gE7UFxbYL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/714ilio7B6L._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/61mGDGzRyGL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/71G9FN8sumL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/81mvxZvWCnL._AC_SL1500_.jpg"],
        description: "Specifically Designed for Inkjet Printer：Hiipoo Sublimation Ink Autofil l Bottles for ET-2720 ET-2710, ET-2760, ET-3710, ET-3760, ET-4760, ET-15000 ET-16600 ET-16650; WorkForce ET-3750, ET-4700, ET-4750.",
        category: "Office Products",
        featured: false,
        stock: 16,
    },
    {
        id: "24",
        name: "Scissors 6-Pack, 8'' Multipurpose Scissor with Ultra Sharp Blades",
        company: "rainwater shah LLC",
        price: 7.09,
        image: ["https://m.media-amazon.com/images/I/71XktumaklL._AC_SL1500_.jpg","https://m.media-amazon.com/images/I/61M3WwpJdIL._AC_SL1500_.jpg" ," https://m.media-amazon.com/images/I/61m+nhSXczL._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/61vWNTq+01L._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/61Ra4ldIK5L._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/71sXNtNLJgL._AC_SL1500_.jpg"],
        description: "[Durable and Sharp Blades]: The blades of scissors are made of Premium steel that makes 3 times harder than normal stainless steel. This allows our scissors to stay sharp after long time use. [Premium Scissors]: The metal scissor has high performance when cutting through fabric, boxes, plastic packages, burlap, paper and light line. It is smooth and efficient in a variety of usage scenarios due to premium steel. Right/Left Handed Scissors]: Soft-grip touchpoints offer a comfort hold and great control. Fit right and left handed users. ",
        category: "Office Products",
        featured: false,
        stock: 16,
    },
    // ------------- catg 5 ------ 
    {
        id: "25",
        name: "MidWest Homes for Pets Cinnamon 18-Inch Pet Bed w/ Comfortable Bolster",
        company: "rainwater shah LLC",
        price: 12.00,
        image: ["https://m.media-amazon.com/images/I/81cdkioNcUL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/91zZiyXGXkL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/81adRlvemmL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/91854t0O5XL._AC_SL1500_.jpg" ," https://m.media-amazon.com/images/I/915p8hJaaLL._AC_SL1500_.jpg "," https://m.media-amazon.com/images/I/71ol0vGsTTL._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/41DxuIES0xL._AC_.jpg "],
        description: "Pet bed fits 18-inch long dog crates & works great as a stand-alone pet bed | Pet bed is ideal for Tiny  dog breeds weighing up to 6 pounds | *Pet bed is not ideal for excessive chewer. TURN YOUR DOG'S CRATE INTO THEIR FOREVER HOME! | The elastic corner straps secure the pet bed to the crate pan to prevent sliding and bunch enticing your pet to use their crate. Pet bed features ultra-soft synthetic fur & comfortable padded polyester bolster cushion around the perimeter for dogs to lay their head on Ideal pet bed for folding metal dog crates, dog carriers, cat carriers & dog houses | This specific size fits a MidWest 18-inch iCrate dog crate. EASY MAINTENANCE PET BED.",
        category: "Pets Supplies",
        featured: true,
        stock: 16,
    },
    {
        id: "26",
        name: "KIPRITII Dog Chew Toys for Puppy - 23 Pack Puppies",
        company: "rainwater shah LLC",
        price: 16.00,
        image: ["https://m.media-amazon.com/images/I/91H6rAF-wWL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/81-h+-8vguL._AC_SL1500_.jpg" ,"https://m.media-amazon.com/images/I/81PksqOifHL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/7166lDTcx6L._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/818DAHbckPL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/815HDhp+h9L._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/81YLBq+iUSL._AC_SL1500_.jpg "],
        description: "Great for Dogs & Protect Your Home: Dogs are chewers by nature, when teething, boredom, loneliness, stress relief, will chew everything. Our dog chew toys designed for chewing dogs to protect your home (like shoes, sofa, pillows) from chewing. Through these exclusive puppy chew toys not only give you a tidy home and make your dog healthy. Dog Toy & Super Fun: 23 Pack pet toys for dogs, great for puppy and small dogs. Includes 8 rope dog toys, 2 dog treat balls. ",
        category: "Pets Supplies",
        featured: false,
        stock: 16,
    },
    {
        id: "27",
        name: "Best Pet Supplies Crinkle Dog Toy for Small, Medium, and Large Breeds.",
        company: "rainwater shah LLC",
        price: 6.21,
        image: ["https://m.media-amazon.com/images/I/61iLNIUwPnL._AC_SL1500_.jpg " ,  "https://m.media-amazon.com/images/I/61FyIuv14aL._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/71EBF+JqM+L._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/61msSFNk9bL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/71L3TtpYtWL._AC_SL1500_.jpg"],
        description: "Premium Soft Chew Toys for Dogs - These adorable dog crinkle toys no stuffing ducks provide your four-legged best friend with an interactive chew toy that makes noise, keeps them engaged, and is gentler on teeth, gums, and dental health. Cute and Colorful Duck Shape - Shaped like a real duck these dog crinkle toys for small dogs, medium dogs, and every size in between comes in 6 unique colors and provides a more puppy friendly shape that's easy to carry around.",
        category: "Pets Supplies",
        featured: false,
        stock: 16,
    },
    {
        id: "28",
        name: "modern Outward Hound Fun Feeder Slo Bowl, Slow Feeder Dog Bowl, Medium/Mini.",
        company: "rainwater shah LLC",
        price: 9.10,
        image: ["https://m.media-amazon.com/images/I/81WahUfd37L._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/81D8PdRpMKL._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/91yVRBcskSL._AC_SL1500_.jpg", "https://m.media-amazon.com/images/I/81SiMlAm+kL._AC_SL1500_.jpg ", "https://m.media-amazon.com/images/I/81fI0ejGFLL._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/81eqr4Fa6YL._AC_SL1500_.jpg , https://m.media-amazon.com/images/I/81p6Vk-DM2L._AC_SL1500_.jpg"],
        description: "SLOWS DOWN EATING UP TO 10X: Outward Hound uniquely designed Fun Feeder dog bowls feature meal-lengthening ridges to help slow down your dog’s eating time by 10X!. AIDS IN PROPER DIGESTION: Common issues that arise in fast-eating dogs include bloating, regurgitation, and canine obesity. Our Fun Feeder Slo Bowls both challenge and engage your dog during mealtime while helping reduce overeating behavior. MADE WITH NON-SLIP BASE AND FOOD SAFE MATERIALS: Outward Hound's Fun Feeder Slo Bowl, slow feeder dog bowls are made to hold in both fun and food as your dog forages through the fun patterns with its non-slip base.",
        category: "Pets Supplies",
        featured: false,
        stock: 16,
    },
    {
        id: "29",
        name: "Zesty Paws Multivitamin Treats for Dogs.",
        company: "rainwater shah LLC",
        price: 29.10,
        image: ["https://m.media-amazon.com/images/I/7183A1-ljvL._AC_SL1500_.jpg","https://m.media-amazon.com/images/I/71vl0T1tX8L._AC_SL1500_.jpg" ," https://m.media-amazon.com/images/I/81Nj4z0dt3L._AC_SL1500_.jpg" , "https://m.media-amazon.com/images/I/81Evhzs7WhL._AC_SL1500_.jpg "],
        description: "America’s #1 Selling Dog Supplement Brand on Amazon*. 8-in-1 Formula - Zesty Paws Multifunctional Bites are functional supplement chews with premium ingredients that support physical performance, antioxidants, hip & joint, heart, immune, skin, liver, & gut health for dogs of all ages, breeds, and sizes.Skin Health & Antioxidants – For animals with sensitive skin, this formula contains Cod Liver Fish Oil and Vitamin E to help maintain normal moisture and CoQ10 to help reduce oxidative stress. Hip, Joint & Performance Support - Each dog chew features OptiMSM, which works synergistically with the Glucosamine HCl and Chondroitin Sulfate in these chews for hip and joint support plus Cod Liver Oil and B-Complex Vitamins support normal physical performance.",
        category: "Pets Supplies",
        featured: false,
        stock: 9,
    },

];