import React from 'react'
import Hero from '../components/Hero'

const About = () => {
  const data = {
    name: "Welcome to Rainwater Shah LLC "
  }
  return (
    <Hero myData = {data} />
  )
}

export default About