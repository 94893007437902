import React from 'react'
import { Link } from 'react-router-dom'
import FormatPrice from '../Helper/FormatPrice'

const FetureSingleProduct = (item) => {
    const {id,  name, image,price,category} = item
    // console.log(id)
  return (
  <Link className='sing-link' to={`/singleproduct/${id}`}>
 
    <div className="card " >

        <figure className='big-fig'>
            <img src={image} alt="name" />
            <figure className='catg'>{category}</figure>
        </figure>
        <div className="card-data">
            <h3>{name}</h3>
            <p> {<FormatPrice price = {price} />}</p>
        </div>
      
    </div>

        
  </Link>
  )
}

export default FetureSingleProduct
