const CreditCardFeilds = () => {
  return (
    <form className="py-3  px-5 my-5 w-[60%] bg-white">
    <h2>BILLING DETAILS</h2>


  
    <div className="flex justify-start flex-col w-full my-4 items-start">

        <input type="text" placeholder='Card Number' className="w-[100%]  h-[34px]   px-[10px] outline-none border-[#9b8a8a] border-[1px]  border-solid" />
    </div>
    <div className="flex flex-col xsm:flex-row gap-2 w-full my-4">
        <div className="flex justify-start flex-col w-[100%] items-start">
          
            <input type="text" placeholder='month'
                className="w-[100%] px-[10px] h-[34px] outline-none border-[#9b8a8a] border-[1px] border-solid " />
        </div>
        <div className="flex justify-start flex-col w-full  items-start">
           
            <input placeholder='Year' type="number" min="2020" max="2030" className="w-[100%] h-[34px] px-[10px] outline-none border-[#9b8a8a] border-[1px] border-solid " />
        </div>
    </div>
    <div className="flex flex-col xsm:flex-row gap-2 w-full my-4">
        <div className="flex justify-start flex-col w-[50%] items-start">
          
            <input type="text" placeholder='CVV'
                className="w-[100%] px-[10px] h-[34px] outline-none border-[#9b8a8a] border-[1px] border-solid " />
        </div>
        
    </div>

</form>
  )
}

export default CreditCardFeilds