import React, { useState } from 'react'

const MyProductImg = ({imgs = [{url:''}]}) => {
    const [mainImge,setMainimge] = useState(imgs[0])
    console.log(imgs)
  return (
 <section className='main-left-img'>
    <div className='sing-img-div1'>
        {imgs.map((curElm,index) =>{
            return (
                <figure>

                    <img src={curElm} alt={curElm.filename} className='box-image--style' key={curElm.url} onClick={() => setMainimge(curElm)} />
                </figure>
                )
        })}
    </div>
  
        <div className='sing-img-div2'>
        <img src={mainImge} alt="" />

        </div>
  
 </section>
  )
}

export default MyProductImg
