import React from 'react'

import { Link } from 'react-router-dom'

const Hero = ({myData}) => {
    const {name} = myData
  return (
  <section>
    <div className="container">
        <div className='two-div'>
            <div className="for-text">
                <p className='p1'>Welcome to</p>
                <h1>{name}</h1>
                <p>Welcome to Rainwater Shah LLC – an immersive online marketplace designed to elevate your shopping experience. Nestled in the heart of the digital retail landscape, Rainwater Shah invites you to embark on a journey of discovery, where innovation meets style, and convenience intertwines with quality.</p>
                <Link className="btn" to={'/products'}>Shop Now</Link>
            </div>
            <div className="for-img">
                <figure>
                    <img src='https://img.freepik.com/free-photo/family-shoppers_1098-13849.jpg?size=626&ext=jpg&ga=GA1.1.557115731.1683793876&semt=ais' alt="" />
                </figure>
            </div>

        </div>
    </div>
  </section>
  )
}

export default Hero
