import React, { useContext } from 'react'
import FetureSingleProduct from './FetureSingleProduct'
import { ProductProvider } from '../Context/ProductContext'



const Gridview = () => {
    const { ProductsData } = useContext(ProductProvider)
  return (
    <section className='fetureProduct-sec'>
    <div className="feture-container">
       
        
        <div className="main-container">
            {
                ProductsData.map((item) => {
                    return <FetureSingleProduct key={item.id} {...item}  />
                })
            }
        </div>
    </div>

</section>
  )
}

export default Gridview