import React from 'react'
import { BsTruck } from 'react-icons/bs'
import { GiPayMoney } from 'react-icons/gi'
import { RiSecurePaymentFill } from 'react-icons/ri'

const Services = () => {
    return (
        <section className='services-sec'>
            <div className='main-services'>
                <div className='box box1'  >
                    <div><BsTruck className='icon' /></div>
                    <h3>Super Fast Free Delivery</h3>
                </div>
                <div className='box2' >
                    <div>
                    <div><GiPayMoney className='icon' /></div>
                    <h3>Super Fast Free Delivery</h3>
                    </div>
                    <div>  
                        
                    <div><GiPayMoney className='icon' /></div>
                    <h3>Super Fast Free Delivery</h3>
                        </div>
                </div >
                <div className='box box3' >
                <div><RiSecurePaymentFill className='icon' /></div>
                    <h3>Super Fast Free Delivery</h3>
                </div>
                
            </div>
        </section>
    )
}

export default Services