import React from 'react'
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom'
import Home from './Pages/Home'
import Header from './components/Header'
import './Styles/main.css'
import './Styles/contact.css'
import './Styles/product.css'
import './Styles/Media.css'
import About from './Pages/About'
import Contact from './Pages/Contact'
import Footer from './components/Footer'
import ErrorPage from './Pages/ErrorPage'
import SingleProduct from './Pages/SingleProduct'
import Cart from './Pages/Cart'
import Products from './Pages/Products'
import SmoothSroll from './components/SmoothSroll'
import Checkout from './Pages/Checkout'
import Order from './Pages/Order'
const App = () => {


  return (
    <div  style={{overflowX:'hidden'}}>
    <Router>
      <SmoothSroll />
      <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About  />} />
        <Route path="/contact" element={<Contact  />} />
        <Route path="*" element={<ErrorPage  />} />
        <Route path="/cart" element={<Cart  />} />
        <Route path="/products" element={<Products  />} />
        <Route path="/checkout" element={<Checkout  />} />
        <Route path="/orderdone" element={<Order  />} />
        <Route path='/singleproduct/:id' element={<SingleProduct/>}></Route>
      </Routes>
      <Footer/>
    </Router>
    </div>
  )
}

export default App
