import React, { createContext, useEffect, useState } from 'react'

export const CartProvider = createContext()




const CartContext = ({children})=> {



const [cart,setCart] = useState([]);

console.log(cart)
// itemAmount state
useEffect(() =>{
    let localCartData = localStorage.getItem("zubiiCart");
  if (!localCartData) {
    return [];
  } else {
    return setCart(JSON.parse(localCartData));
  }

},[])
      const [itemAmount,setItemAmount] = useState(0)
      // cart state
  const [total,setTotal] = useState(0)
  // add to cart 
const addToCart = (product,id) =>{
    const newItem = {...product,quantity:1}
    console.log(product)
    //  cheak item is alredy in cart or not 
  const Cartitem = cart.find(item => item[0].id === id)
  console.log(Cartitem)
  // if cart item in cart 
  
  if (Cartitem){
    
    const newCart = [...cart].map(item =>{
        if(item[0].id === id && item[0].stock > item.quantity ){
            return {...item, quantity: Cartitem.quantity + 1}
    } else {
    return item
  }
    });
    setCart(newCart);
} else{
    setCart([...cart, newItem])
}
}
// udate item amount in home 
useEffect(() =>{
    if(cart){
        const amount = cart.reduce((accumalator,currentItem) =>{
            return accumalator + currentItem.quantity;
        },0);
        setItemAmount(amount)
    }
}, [cart])

useEffect(() =>{
    const total = cart.reduce((accumalator,currentItem) =>{
        return accumalator + currentItem[0].price * currentItem.quantity;
    },0);
    setTotal(total)
},[cart])

// Remove Cart 
const removeFromCart = (id) =>{
    const newCart = cart.filter((item) =>{
    return item[0].id !== id;
  });
  setCart(newCart)
  }
  
  // clear cart 
  const clearCart = () =>{
  setCart([])
  }
  
  // increase amount 
  const increaseAmount = (id) =>{
    const cartItem = cart.find((item) => item[0].id === id);
    addToCart(cartItem,id)
  }
  
  // descrease 
  const decreaseAmount = (id) => {
    const cartItem = cart.find((item) => {
        return item[0].id ===  id;
    });
    if (cartItem) {
    const newCart = cart.map(item => {
      if (item[0].id === id){
        return {...item,quantity: cartItem.quantity < 2 ? 1 : cartItem.quantity - 1}
      } else {
        return item;
      }
    });
    setCart(newCart);
} 
  
  
  }
  
  useEffect(() =>{
    localStorage.setItem("zubiiCart", JSON.stringify(cart));
}, [cart]);



  return <CartProvider.Provider value={{addToCart,removeFromCart,cart,total,itemAmount,decreaseAmount,increaseAmount,clearCart}}>{children}</CartProvider.Provider>
}

export default CartContext